"use client";

import React from "react";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

export default function TonConnectProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <TonConnectUIProvider manifestUrl="https://mini.playground.sweet.io/tonconnect-manifest.json">
      {children}
    </TonConnectUIProvider>
  );
}
