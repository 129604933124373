"use client";

import { datadogRum } from "@datadog/browser-rum";
import { initializeGA } from "../../hooks/ga4/utils";
import { useEffect } from "react";

interface AnalyticsProps {
  children: React.ReactNode;
}

const Analytics = ({ children }: AnalyticsProps) => {
  useEffect(() => {
    // Initialize Google Analytics
    initializeGA();

    // Initialize DataDog RUM
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DDRUM_APP_ID || "",
      clientToken: process.env.NEXT_PUBLIC_DDRUM_CLIENT_TOKEN || "",
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.com",
      service: "miniapp",
      env: process.env.NEXT_PUBLIC_APP_STAGE,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  }, []);

  return children;
};

export default Analytics;